import React from 'react';
import Layout, { TEMPLATE } from '../../components/Layout';

const title = 'Home';

const HomePage = () => (
  <Layout title={title} template={TEMPLATE.TEXT}>
    <h1>{title}</h1>
  </Layout>
);

export default HomePage;
